import React from 'react'
import { graphql } from 'gatsby'
// import { css } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming'
import styles from '../constants/theme'

import SEO from '../components/seo'
// import Link from "../components/Link";
import Layout from '../components/layout'
import Text from '../components/Text'
import Hero from '../components/Hero'

import HeroImage from '../images/VirtualSymposium.png'
import HeroImage2021PD1 from '../images/HeroImage2021PD1.png'
import Spring2021PD2 from '../images/Spring2021PD2.png'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'

export const pageQuery = graphql`
  query($id: String!) {
    pdPage: wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`

export default function ConferencePage({ data }) {
  let hero
  if (data.pdPage.title === 'Winter 2021 PD') {
    hero = HeroImage
  } else if (data.pdPage.title === 'Spring 2021 PD 1') {
    hero = HeroImage2021PD1
  } else if (data.pdPage.title === 'Spring 2021 PD 2') {
    hero = Spring2021PD2
  }
  return (
    <Layout>
      <SEO title={data.pdPage.title} keywords={['']} />
      <ThemeProvider theme={styles.palette.blue}>
        {data.pdPage.title === 'Winter 2021 PD' ||
        data.pdPage.title === 'Spring 2021 PD 1' ||
        data.pdPage.title === 'Spring 2021 PD 2' ? (
          <MainContentWrap style={{ padding: '0' }}>
            <ContentSection style={{ marginBottom: '0' }}>
              <img src={hero} alt="" width="100%" />
            </ContentSection>
          </MainContentWrap>
        ) : (
          <Hero>
            <div className="container text-center">
              <Text h1>{data.pdPage.title}</Text>
            </div>
          </Hero>
        )}
      </ThemeProvider>
      <MainContentWrap>
        <CustomContainer>
          <ContentSection>
            <div dangerouslySetInnerHTML={{ __html: data.pdPage.content }} />
          </ContentSection>
        </CustomContainer>
      </MainContentWrap>
    </Layout>
  )
}
